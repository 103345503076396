.el-form {
  .labelTitle {
    label {
      font-size: 18px;
      line-height: 1.44;
      color: $primary-green;
    }
  }

  .el-form-item {
    margin-top: 24px;
    margin-bottom: 32px;
  }

  .big_img {
    width: 192px;
    display: inline-block;
    margin-right: 30px;
    cursor: pointer;
    padding: 3px;
    border: 1px solid transparent;

    .backgroung {
      background-color: $primary-purple;
    }

    img {
      max-width: 145px;
      margin: 10px calc((100% - 145px) / 2);
    }

    &.active {
      box-shadow: 0 8px 21px 0 rgba(0, 81, 255, 0.35);
      border: solid 1px $primary-purple;
    }
  }

  .currency_img {
    display: inline-block;
    margin-right: 40px;
    cursor: pointer;
    padding: 3px;
    border: 1px solid transparent;

    &.active {
      box-shadow: 0 8px 21px 0 rgba(0, 81, 255, 0.35);
      border: solid 1px $primary-purple;
    }

    .shadow_inner img {
      width: 45px;
    }
  }

  .tnc {
    border: $container-border;
    border-radius: $container-radius;
    padding: 20px;
    margin: 40px 0;

    .checkStatus {
      margin-top: 24px;
      margin-bottom: 32px;

      /deep/ .el-form-item__content {
        line-height: 1;
      }

      /deep/ .el-checkbox__label {
        font-size: 16px;
        font-weight: 200;
        line-height: 1.56;
        color: $primary-purple;
      }

      .error {
        margin-top: 8px;
        color: #ed164e;
        margin-left: 33px;
      }
    }
  }

  .pamm_tips {
    font-size: 14px;
    color: $red;
  }

  /deep/ ul {
    padding-left: 28px;

    li {
      font-weight: 200;
      line-height: 22px;
      color: $primary-purple;
      margin-bottom: 10px;
      list-style: decimal;
      padding-left: 1em;
    }
  }
}

@media (max-width: 550px) {
  .el-form {
    .el-form-item {
      margin-top: 22px;
    }

    .big_img + .big_img {
      margin-top: 20px;
    }

    .checkStatus {
      margin-top: 32px;
    }
  }
}
